<template>
  <div>
    <v-bottom-sheet v-model="opened">
      <v-card>
        <v-card-title>
          <h1 class="title">Ações para agendamento</h1>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-list class="text-center">
            <v-list-item class="border-bottom-grey" @click="openDetails">
              <v-list-item-title>
                <v-icon color="secondary">add_chart</v-icon>
                Ver detalhes
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isNotCancelled"
              class="border-bottom-grey"
              @click="openCancellationDialog"
            >
              <v-list-item-title>
                <v-icon color="error">remove_circle_outline</v-icon>
                Cancelar agendamento
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <cancellation-dialog ref="cancellationDialog" @submit="cancelled" />
    <detail-dialog ref="detailDialog" />
  </div>
</template>

<script>
export default {
  name: "ListActions",
  components: {
    CancellationDialog: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/CancellationDialog"
      ),
    DetailDialog: () =>
      import(
        "@/domains/appointments/therapist/presentation/components/DetailDialog"
      ),
  },
  data: () => ({
    opened: false,
    selectedAppointment: {},
  }),
  computed: {
    isNotCancelled() {
      return this.selectedAppointment.status !== 4;
    },
  },
  methods: {
    open({
      id,
      status,
      startAt,
      cancellationReason,
      cancellationSource,
      therapistName,
    }) {
      this.selectedAppointment = {
        id,
        status,
        startAt,
        cancellationReason,
        cancellationSource,
        therapistName,
      };
      this.opened = true;
    },
    openCancellationDialog() {
      this.$refs.cancellationDialog.open({
        appointmentId: this.selectedAppointment.id,
        source: "patient",
      });
    },
    openDetails() {
      this.$refs.detailDialog.open(this.selectedAppointment.id);
      this.opened = false;
    },
    cancelled({ appointmentId, newStatus }) {
      this.$emit("cancelled", { appointmentId, newStatus });
      this.opened = false;
    },
  },
};
</script>

<style scoped></style>
